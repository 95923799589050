import React       from 'react';
import { graphql } from 'gatsby';
import Img         from 'gatsby-image';
import { Center }  from '@chakra-ui/react';

import Heading       from '@interness/web-core/src/components/text/Heading/Heading';
import HeaderMedia   from '@interness/theme-default/src/components/HeaderMedia';
import Wrapper       from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import Spacer        from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Separator     from '@interness/web-core/src/components/structure/Separator/Separator';
import { findMedia } from '@interness/web-core/src/components/_helpers';


const MoebelPage = (props) => {
  return (
    <>
      <HeaderMedia id="moebel"/>

      <Spacer/>
      <Wrapper>
        <Heading subtitle="Trendig, hipp und multifunktional">Möbel</Heading>
        <Spacer/>
        <h3>Die Europalette als Alleskönner</h3>
        <p>Trendig, hipp und multifunktional. So bietet die Europalette heute viele Möglichkeiten einer individuellen
          Raumgestaltung. Darüber hinaus ist sie nachhaltig, umweltschonend und prägt einen verantwortungsvollen
          Lebensstil.</p>
        <Spacer/>
        <h3>Designobjekte</h3>
        <p>Palettenmöbel entstehen aus individuellen Wünschen, passend zum eigenen Typ und den Anforderungen der
          Räumlichkeiten. Ob als Bett, Tisch, Sitzbank oder Ablage. Die Europalette ist multifunktional. Kreativität
          entwickelt sich hier beim Möbelbau zu einer völlig neuen und wohnlichen Lebensgestaltung.</p>
        <Spacer/>
        <h3>Tradition bewahren und Zukunft gestalten</h3>
        <p>Die Kombination aus langjähriger Erfahrung, modernen Fertigungsanlagen sowie absolut hochwertigen Materialien
          sind der Garant für optimale Qualität unserer Holzpaletten. Als EPAL lizenzierter Hersteller garantieren wir
          höchste Güte und umweltverträgliche Nachhaltigkeit bei der Produktion der Europaletten.</p>
        <Separator/>
        <h4 style={{textAlign: 'center'}}>Matschküche für Kinder</h4>
        <Center>
          <Img fixed={findMedia(props.data.collection.images, 'matschkueche').childImageSharp.fixed}/>
        </Center>
        <Separator/>
        <h4 style={{textAlign: 'center'}}>Paletten-Sofa</h4>
        <Center>
          <Img fixed={findMedia(props.data.collection.images, 'pg-moebel-4').childImageSharp.fixed}/>
        </Center>
        <Separator/>
        <h4 style={{textAlign: 'center'}}>Paletten-Tische</h4>
        <Center>
          <Img fixed={findMedia(props.data.collection.images, 'pg-moebel-3').childImageSharp.fixed}/>
        </Center>
      </Wrapper>
      <Spacer/>
    </>
  )
};

export default MoebelPage;

export const query = graphql`
    query {
        collection: directusMediaCollection(name: {eq: "palettenmoebel"}) {
            name
            images: media {
                file {
                    localFile {
                        name
                        childImageSharp {
                            fixed {
                                ...GatsbyImageSharpFixed
                            }
                        }
                    }
                }
            }
        }
    }
`;